export const Products = [
  {
    name: "エロティカセブン",
    goodsHeaderId: 3,
    variants: [
      {
        goodsId: 9,
        name: "1本",
        color: null,
        size: null,
        price: 9980,
        thumb: "erotica1.jpg",
      },
      {
        goodsId: 10,
        name: "2本",
        color: null,
        size: null,
        price: 17500,
        thumb: "erotica2.jpg",
      },
      {
        goodsId: 11,
        name: "3本",
        color: null,
        size: null,
        price: 25000,
        thumb: "erotica3.jpg",
      },
      {
        goodsId: 12,
        name: "5本",
        color: null,
        size: null,
        price: 40500,
        thumb: "erotica5.jpg",
      },
    ],
  },
  {
    name: "フュージョンEXプラス",
    goodsHeaderId: 5,
    variants: [
      {
        goodsId: 37,
        name: "1箱",
        color: null,
        size: null,
        price: 7980,
        thumb: "fusion1.png",
      },
      {
        goodsId: 38,
        name: "2箱",
        color: null,
        size: null,
        price: 15360,
        thumb: "fusion2.png",
      },
      {
        goodsId: 39,
        name: "3箱",
        color: null,
        size: null,
        price: 21120,
        thumb: "fusion3.png",
      },
      {
        goodsId: 40,
        name: "5箱",
        color: null,
        size: null,
        price: 34000,
        thumb: "fusion5.png",
      },
    ],
  },
  {
    name: "F for MEN",
    goodsHeaderId: 6,
    variants: [
      {
        goodsId: 44,
        name: "1本",
        color: null,
        size: null,
        price: 6578,
        thumb: "fformen1.jpg",
      },
      {
        goodsId: 45,
        name: "2本",
        color: null,
        size: null,
        price: 12628,
        thumb: "fformen2.jpg",
      },
      {
        goodsId: 46,
        name: "3本",
        color: null,
        size: null,
        price: 18150,
        thumb: "fformen3.jpg",
      },
      {
        goodsId: 47,
        name: "5本",
        color: null,
        size: null,
        price: 27500,
        thumb: "fformen5.jpg",
      },
    ],
  },
]

export const Products2 = [
  {
    name: "バラグアゴールドα",
    goodsHeaderId: 13,
    variants: [
      {
        goodsId: 74,
        name: "1箱",
        color: null,
        size: null,
        price: 2980,
        thumb: "viragua1.jpg",
      },
      {
        goodsId: 79,
        name: "2箱",
        color: null,
        size: null,
        price: 5800,
        thumb: "viragua2.jpg",
      },
      {
        goodsId: 80,
        name: "3箱",
        color: null,
        size: null,
        price: 8480,
        thumb: "viragua3.jpg",
      },
      {
        goodsId: 81,
        name: "5箱",
        color: null,
        size: null,
        price: 13800,
        thumb: "viragua5.jpg",
      },
    ],
  },
  {
    name: "レビトラックス",
    goodsHeaderId: 19,
    variants: [
      {
        goodsId: 87,
        name: "1箱",
        color: null,
        size: null,
        price: 3480,
        thumb: "revvi1.jpg",
      },
      {
        goodsId: 93,
        name: "2箱",
        color: null,
        size: null,
        price: 6960,
        thumb: "revvi2.jpg",
      },
      {
        goodsId: 94,
        name: "3箱",
        color: null,
        size: null,
        price: 10400,
        thumb: "revvi3.jpg",
      },
      {
        goodsId: 95,
        name: "5箱",
        color: null,
        size: null,
        price: 17400,
        thumb: "revvi5.jpg",
      },
    ],
  },
]
